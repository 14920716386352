import { arrayUnion, arrayRemove } from "vuex-easy-firestore";

const jimstaSlideSlots = {
  firestorePath: 'jimstaSlideSlots',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'jimstaSlideSlots',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    debounceTimerMs: 50,
    guard: ['valid']
  },

  // you can also add state/getters/mutations/actions
  state: {
    trim: false,
    crop: false,
    fileID: ''
  },
  getters: {
    byId: (state) => (id) => {
      return state.data[id]
    },
    isSlotValid: (state) => (id) => {
      const slotData = state.data[id]
      if (slotData.overlimit) { // if it's over limit, that's fine it will be ignored on render
        return true
      } else if (slotData.fileID) { // so if not overlimit, check if it has a file
        return true
      } else { // so otherwise it's underlimit and has no file
        return false
      }
    },
    bySlideId: (state) => (id) => {
      let slots = Object.values(state.data)
      return slots.filter(f => f.slideID === id)
    },
    slideSlotInfo: (state) => (slotList) => {
      let slots = [];

      for (var j = 0; j < slotList.length; j++) {

        slots.push({
          ...state.data[
          slotList[j]
          ]

        });
      }
      return slots

    },
    byJimstaId: (state) => (id) => {
      let slots = Object.values(state.data)
      return slots.filter(f => f.jimstaID === id)
    },
    trimStatus: (state) => state.trim,
    cropStatus: (state) => state.crop,
    editFile: (state) => state.fileID
  },
  mutations: {
    trimModal(state, payload) {
      state.trim = payload.trim
      state.fileID = payload.fileID
    },
    cropModal(state, payload) {
      state.crop = payload.crop
      state.fileID = payload.fileID
    }
  },
  actions: {
    addSlot({ dispatch }, payload) {
      dispatch("insert", {
        overlimit: false,
        slideID: payload.slideID,
        jimstaID: payload.jimstaID,
      }).then(slotID => {
        dispatch("jimstaSlides/patch", { id: payload.slideID, slots: arrayUnion(slotID) }, { root: true })
      })
    },
    moveFiles({ dispatch }, payload) {
      let from = {}
      from.id = payload.fromSlotID
      from.fileID = payload.fromFileID
      dispatch("patch", from);
      let to = {}
      to.id = payload.toSlotID
      to.fileID = payload.toFileID
      dispatch("patch", to);
    }
  },

}

export default jimstaSlideSlots