<template>
  <div>
    <base-header class="pb-9" type="danger"> </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="h3 mb-0">Query</div>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col">
                                    <form @submit.prevent="searchIt">
                  <base-input

                    placeholder="Search for player name"
                    type="text"
                    v-model="search"
                  >
                  </base-input>
                                    </form>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <!-- <div class="card">
            <div class="card-body">
              <ul class="list-group list-group-flush list event-list">
                <li
                  v-for="match in matches"
                  class="list-group-item px-0"
                  :key="match.id"
                >
                  <match :match="match" />
                </li>
              </ul>
            </div>
          </div> -->

          <card
            class="no-border-card"
            body-classes="px-0 pb-1"
            footer-classes="pb-2"
          >
            <el-table
              class="matchesTable"
              :data="searchResults"
              row-key="id"
              header-row-class-name="thead-light"
            >

              <el-table-column
                min-width="200px"
                prop="_source.fullName"
                sortable
              >
              </el-table-column>
                    <el-table-column
                min-width="200px"
                label="Ellipse ID"
                prop="_source.ellipsePlayerId"

              >
              </el-table-column>

              <!--    <el-table-column label="Format" min-width="100px" prop="cricketFormat" sortable>
                <template v-slot="{ row }">
                  {{ row.cricketFormat }}
                </template>
              </el-table-column> -->
              <el-table-column label="Pulselive ID" min-width="200px" prop="pulseID">
                <template v-slot="{ row }">
                  <base-input

                    type="number"
                    v-model="row._source.pulseID"
                    @input="pulseID($event, row._id)"
                  >
                  </base-input>
                </template>
              </el-table-column>

            </el-table>


          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Firebase } from "@/firebase";
export default {
  data() {
    return {
      pagination: {
        perPage: 25,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      search: "",
      searchResults: [],
    };
  },
  components: {},
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
  },
  beforeMount() {

  },
  methods: {
    pulseID(value, id) {
      console.log(value);
      console.log(id);
      this.$store.dispatch("players/patch", { id: id, pulseID: value });
    },
    async searchIt() {
      let payload = {
        response: null,
        request: {
          index: "players",
          body: {
            query: {
              bool: {
                should: [
                  {
                    match: {
                      fullName: {
                        query: this.search,
                        fuzziness: "AUTO",
                      },
                    },
                  },
                ],
              },
            },
          },
        },
      };

      this.$store.commit("user/setLoading", true);
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);
    //   console.log(result.id);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          console.log(d)
          if (d.response !== null) {
            // Do things
            this.searchResults = d.response.hits.hits;
            this.searchHits = d.response.hits.total.value;
            this.searchReturn = d.response.hits.hits.length;
            this.$store.commit("user/setLoading", false);
          }
        });
    },
  },
};
</script>

<style>
.el-table__expand-column {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.select-danger .el-select .el-tag {
  background: #f5365c;
}
</style>
