<template>
  <div>
    <base-header class="pb-9 pt-3 mt-0" type="danger">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3>Query Builder</h3>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col">
                  <base-input>
                    <el-select
                      v-model="filters"
                      filterable
                      multiple
                      placeholder="Add filters"
                    >
                      <el-option
                        v-for="option in filterOptionArray"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-auto" v-if="filters.length > 0">
                  <base-input>
                    <base-button @click="runQuery">Fetch</base-button>
                  </base-input>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-4" v-for="filter in filters" :key="filter">
                  <filter-selector
                    :selection="filterOptions[filter]"
                    :preValue="filterValues[filter]"
                    @valueChange="filterValueChange($event, filter)"
                  >
                  </filter-selector>
                </div>
              </div>
              <div class="row align-items-center">
                <!-- <div class="col-4">
                  <base-input label="Display Mode">
                    <el-select
                      v-model="displayMode"
                      placeholder="Display Options"
                    >
                      <el-option
                        v-for="option in displayModes"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div> -->
                <div class="col-6">
                  <base-input label="Match Fields">
                    <el-select
                      v-model="matchDisplayFields"
                      @change="matchDisplayFieldsSet($event)"
                      filterable
                      multiple
                      placeholder="Match Criteria"
                    >
                      <el-option
                        v-for="option in matchDisplayOptions"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-6">
                  <base-input label="Delivery Fields">
                    <el-select
                      v-model="displayFields"
                      @change="displayFieldsSet($event)"
                      filterable
                      multiple
                      placeholder="Delivery Criteria"
                    >
                      <el-option
                        v-for="option in displayOptions"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-if="selected.length > 0">
            <div class="card-header">
              <h3>Selection</h3>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center mb-3">
                <div class="col">{{ selected.length }} clips selected</div>
                <div class="col-auto">
                  <!-- <a href="#/archive/collections" class="btn btn-default"
                    >Add to a Collection</a
                  >
                  <base-button>Send to Jimstagram</base-button> -->
                  <base-button @click="clearSelection" type="secondary"
                    >Clear Selection</base-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <!-- masonry display -->
      <div class="row" v-if="displayMode === 'Cards'">
        <div class="col">
          <masonry
            :cols="{ default: 5, 1400: 4, 1024: 3, 768: 2, 576: 1 }"
            :gutter="30"
          >
            <archive-clip
              v-for="clip in clips"
              :clip="clip"
              :key="clip.id"
            ></archive-clip>
          </masonry>
        </div>
      </div>
      <!-- table display -->
      <div class="row" v-else>
        <div class="col">
          <card
            class="no-border-card"
            body-classes="px-0 py-0"
            footer-classes="pb-2"
          >
            <el-table
              class="matchesTable"
              :data="clips"
              row-key="id"
              header-row-class-name="thead-light"
            >
              <el-table-column type="expand">
                <template v-slot="{ row }"> </template>
              </el-table-column>
              <el-table-column type="selection" width="55"> </el-table-column>
              <el-table-column label="Match" prop="match">
                <template v-slot="{ row }">
                  <match-look-up :matchID="row.match"></match-look-up>
                </template>
              </el-table-column>
              <el-table-column label="Date" prop="startDate" sortable>
                <template v-slot="{ row }">
                  {{ row.date | date }}
                </template>
              </el-table-column>
              <el-table-column label="venue" prop="ground">
                <template v-slot="{ row }">
                  <venue-look-up :venueID="row.ground"></venue-look-up>
                </template>
              </el-table-column>
              <el-table-column label="Comp Stage" prop="competition">
              </el-table-column>
              <el-table-column
                label="Comp Name"
                prop="competitionName"
                sortable
              >
              </el-table-column>
              <el-table-column label="Type" prop="cricketType" sortable>
                <template v-slot="{ row }">
                  <type-look-up :typeID="row.cricketType"></type-look-up>
                </template>
              </el-table-column>
              <el-table-column label="Format" prop="cricketFormat">
                <template v-slot="{ row }">
                  <format-look-up
                    :formatID="row.cricketFormat"
                  ></format-look-up>
                </template>
              </el-table-column>

              <!-- <el-table-column min-width="200px" prop="name">
                <template v-slot="{ row }">
                  <a
                    :href="'#/archive/match/' + row.id"
                    class="font-weight-bold"
                    >Go To Media</a
                  >
                </template>
              </el-table-column> -->
            </el-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveClip from "./Components/ArchiveClip.vue";
import FilterSelector from "./Components/FilterSelector";
import VenueLookUp from "./Components/VenueLookUp";
import MatchLookUp from "./Components/MatchLookUp";
import TypeLookUp from "./Components/TypeLookUp";
import FormatLookUp from "./Components/FormatLookUp";
export default {
  name: "clip-search",
  components: {
    "archive-clip": ArchiveClip,
    "filter-selector": FilterSelector,
    "match-look-up": MatchLookUp,
    "venue-look-up": VenueLookUp,
    "type-look-up": TypeLookUp,
    "format-look-up": FormatLookUp,
  },
  data() {
    return {
      displayModes: ["Table", "Cards"],
      displayMode: "Cards",
      filterOptions: {
        // startDate: { field: "startDate", label: "Match Start", type: "date-range" },
        bookmark: {
          field: "bookmark",
          label: "Tag",
          collection: "archiveTemp",
          collectionIsArray: true,
          collectionGetter: "bookmarkOptions",
          type: "single-select",
        },
        bookmarkPlayer: {
          field: "bookmarkPlayer",
          index: "players",
          searchField: "fullName",
          label: "Tagged Player",
          type: "single-search",
        },
        bookmarkTeam: {
          field: "bookmarkTeam",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Tagged Team",
          type: "single-select",
        },
        clipped: {
          field: "clipped",
          label: "Clipped",
          type: "boolean",
        },
        created_by: {
          field: "created_by",
          label: "Clipped By",
          collection: "users",
          collectionLabel: "name",
          collectionGetter: "users",
          type: "single-select",
        },
        year: {
          field: "year",
          label: "Year",
          type: "number",
        },
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "single-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "single-select",
        },
        batTeam: {
          field: "batTeam",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Batting Team",
          type: "single-select",
        },
        bowlTeam: {
          field: "bowlTeam",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Bowling Team",
          type: "single-select",
        },
        batter: {
          field: "batter",
          index: "players",
          searchField: "fullName",
          label: "Batter",
          type: "single-search",
        },
        bowler: {
          field: "bowler",
          index: "players",
          searchField: "fullName",
          label: "Bowler",
          type: "single-search",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "single-select",
        },
        wicket: {
          field: "wicket",
          label: "Wicket",
          type: "boolean",
        },
        runs: {
          field: "runs",
          label: "Runs",
          type: "number",
        },
        bowlLength: {
          field: "bowlLength",
          label: "Bowling Length",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlLength",
          type: "single-select",
        },
        bowlLine: {
          field: "bowlLine",
          label: "Bowling Line",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlLine",
          type: "single-select",
        },
        bowlMove: {
          field: "bowlMove",
          label: "Bowling Movement",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlMove",
          type: "single-select",
        },
        bowlStyle: {
          field: "bowlStyle",
          label: "Bowling Style",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlStyle",
          type: "single-select",
        },
        fieldAct: {
          field: "fieldAct",
          label: "Fielder Action",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "fieldAct",
          type: "single-select",
        },
        fieldPos: {
          field: "fieldPos",
          label: "Fielder Position",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "fieldPos",
          type: "single-select",
        },
        footMove: {
          field: "footMove",
          label: "Foot Movement",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "footMove",
          type: "single-select",
        },
        howOut: {
          field: "howOut",
          label: "How Out",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "howOut",
          type: "single-select",
        },
        shotType: {
          field: "shotType",
          label: "Shot Type",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "shotType",
          type: "single-select",
        },
      },
      pagination: {
        default: 1,
        chunkSize: 25,
      },
    };
  },
  beforeMount() {
    this.$store.dispatch("teams/fetchAndAdd");
    this.$store.dispatch("players/fetchAndAdd");
    this.$store.dispatch("venues/fetchAndAdd");
    this.$store.commit("archiveEvents/RESET_VUEX_EASY_FIRESTORE_STATE");
    this.$store.dispatch("users/openDBChannel");
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    filters: {
      // getter
      get: function () {
        return this.$store.getters["archiveTemp/deliverySearchFilters"];
      },
      // setter
      set: function (newValue) {
        this.$store.commit("archiveTemp/deliverySearchFilters", newValue);
      },
    },
    filterValues() {
      return this.$store.getters["archiveTemp/deliverySearchValues"];
    },
    matches() {
      return this.$store.getters["matches/array"];
    },
    teams() {
      return this.$store.getters["teams/array"];
    },
    venues() {
      return this.$store.getters["venues/array"];
    },
    matchFormats() {
      return this.$store.getters["matchFormats/array"];
    },
    clips() {
      return this.$store.getters["archiveEvents/array"];
    },
    matchDisplayFields() {
      return this.$store.getters["archiveTemp/matchDisplayFields"];
    },
    matchDisplayOptions() {
      return this.$store.getters["archiveTemp/matchDisplayOptions"];
    },
    displayFields() {
      return this.$store.getters["archiveTemp/displayFields"];
    },
    displayOptions() {
      return this.$store.getters["archiveTemp/displayOptions"];
    },
    filterOptionArray() {
      return Object.values(this.filterOptions);
    },
    selected() {
      return this.$store.getters["archiveTemp/eventSelection"];
    },
  },
  watch: {
    clips: function (newOne, old) {
      let files = {};
      let players = {};
      newOne.forEach((clip) => {
        // console.log(clip.file);
        if (clip.file) {
          files[clip.file] = true;
          if (clip.batter) players[clip.batter] = true;
          if (clip.bowler) players[clip.bowler] = true;
        }
                if (clip.clipFile) {
          files[clip.clipFile] = true;
        }
      });
      // load the files
      files = Object.keys(files);
      console.log(files);
      files.forEach((file) => {
        let res = this.$store.getters["files/fileById"](file);
        if (res === undefined) {
          this.$store.dispatch("files/fetchById", file);
        }
      });
      // load the players
      players = Object.keys(players);
      players.forEach((player) => {
        let res = this.$store.getters["players/byId"](player);
        if (res === undefined) {
          this.$store.dispatch("players/fetchById", player);
        }
      });
    },
  },
  methods: {
    runQuery() {
      this.$store.commit("archiveEvents/RESET_VUEX_EASY_FIRESTORE_STATE");
      let filters = this.filters;
      let where = [
        ["video", "==", true]
        ];
      filters.forEach((field) => {
        if (this.filterValues[field]) {
          // check to make sure the filter has a value
          const value = this.filterValues[field];
          const option = this.filterOptions[field];
          if (
            option.type === "single-select" ||
            option.type === "single-search"
          ) {
            where.push([field, "==", value]);
          } else if (option.type === "multi-select") {
            value.forEach((v) => {
              where.push([`${field}.${v}`, "==", true]);
            });
          } else if (option.type === "boolean") {
            //wicket is saved in the DB as 1 and not boolean (which is a mistake), so map to 1 and 0
            if (field === 'wicket') {
                where.push(['wicket', "==", Number(value)]);
            } else {
            where.push([field, "==", value]);
            }
          } else if (option.type === "number") {
            where.push([field, "==", parseInt(value)]);
          }
        }
      });
      console.log(where);
      // const orderBy = ['created_at', 'desc']

      this.$store.dispatch("archiveEvents/fetchAndAdd", {
        clauses: { where, limit: 100 },
      });
    },
    clearSelection() {
      this.$store.commit("archiveTemp/clearSelection");
    },
    displayFieldsSet(value) {
      this.$store.commit("archiveTemp/displayFields", value);
    },
    filterValueChange(value, filter) {
      this.$store.commit("archiveTemp/deliverySearchValues", {
        value: value,
        filter: filter,
      });
    },
    matchDisplayFieldsSet(value) {
      this.$store.commit("archiveTemp/matchDisplayFields", value);
    },
  },
};
</script>

<style>
</style>