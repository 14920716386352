// ~store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import VuexEasyFirestore from 'vuex-easy-firestore'
Vue.use(Vuex)


import { Firebase, initFirebase } from '../firebase.js'
//  productions
import briefs from './modules/briefs.js'
import deliverables from './modules/deliverables.js'
// import deliverableFolders from './modules/deliverableFolders.js'
import collections from './modules/collections.js'
import users from './modules/users.js'
import preApprovedUsers from './modules/preApprovedUsers.js'
import user from './modules/user.js'
import config from './modules/config.js'
import brands from './modules/brands.js'
import files from './modules/files.js'
import notes from './modules/notes.js'
import shifts from './modules/shifts.js'
import shoots from './modules/shoots.js'
import resources from './modules/resources.js'
import billings from './modules/billings.js'
import costs from './modules/costs.js'
import budgets from './modules/budgets.js'
import payments from './modules/payments.js'
import inspirations from './modules/inspirations.js'
import inspirationComments from './modules/inspirationComments.js'
import inspirationTags from './modules/inspirationTags.js'
import zips from './modules/zips.js'
import temp from './modules/temp.js'
//jimstagram
import jimstaTemplates from './modules/jimstaTemplates.js'
import jimstaTemplateFamilies from './modules/jimstaTemplateFamilies.js'
import jimstas from './modules/jimstas.js'
import jimstaSlides from './modules/jimstaSlides.js'
import jimstaSlideSlots from './modules/jimstaSlideSlots.js'
import jimstaSlideFields from './modules/jimstaSlideFields.js'
import jimstaControl from './modules/jimstaControl.js'
import jimstaServer from './modules/jimstaServer.js'
import musicTags from './modules/musicTags.js'
//streaming
import livestreams from './modules/livestreams.js'
import brightcovePlayers from './modules/brightcovePlayers.js'
import rtmpOutputs from './modules/rtmpOutputs.js'

//archive
import matches from './modules/matches.js'
import teams from './modules/teams.js'
import venues from './modules/venues.js'
import players from './modules/players.js'
import competitions from './modules/competitions.js'
import archiveEvents from './modules/archiveEvents.js'
import archiveTemp from './modules/archiveTemp.js'
import matchFormats from './modules/matchFormats.js'
import clippingRequests from './modules/clippingRequests.js'


// do the magic 🧙🏻‍♂️
const easyFirestore = VuexEasyFirestore(
  [briefs, deliverables, collections, users, preApprovedUsers, user, config, brands,
    resources, billings, budgets, payments, shifts, costs,
    files, notes,  inspirations, inspirationComments, inspirationTags, shoots, zips,
    jimstas, jimstaSlides, jimstaSlideSlots, jimstaSlideFields,
    jimstaTemplates, jimstaControl, jimstaServer,
    livestreams, brightcovePlayers, rtmpOutputs, matches, teams,
    venues, competitions, matchFormats, players, archiveEvents, clippingRequests],
  { logging: true, FirebaseDependency: Firebase }
)

// include as PLUGIN in your vuex store
// please note that "myModule" should ONLY be passed via the plugin
const storeData = {
  plugins: [easyFirestore],
  // ... your other store data
  modules: {
    // inherits the namespace from parent module
    temp, archiveTemp
  }

}

// initialise Vuex
const store = new Vuex.Store(storeData)

// initFirebase
initFirebase()
  .catch(error => {
    // take user to a page stating an error occurred
    // (might be a connection error, or the app is open in another tab)
  })

export default store