const jimstaServer = {
  firestorePath: 'config/jimstaServer',
  firestoreRefType: 'doc', // or 'doc'
  moduleName: 'jimstaServer',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    // debounceTimerMs: 50
    // defaults to 1000
  },
  serverChange: {
    convertTimestamps: {
      ping: '%convertTimestamp%', // default
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },
  // you can also add state/getters/mutations/actions
  state: {
  },
  getters: {
    get: (state) => {
      return state.data
    }
  },
  mutations: {},
  actions: {
  
          
  
  }
}
export default jimstaServer