<template>
  <div>
    <base-header
      class="pb-5 pt-4"
      :type="$route.meta.headerClass ? $route.meta.headerClass : 'success'"
    >
    </base-header>
  <div class="container mt--4">
    <div class="row">
      <div class="col">
        <div class="card-wrapper">
          <div class="card">
            <div class="card-body">
              <div class="row">
               
                <div class="col-4">
                  Name:
                  </div>
                <div class="col">
                  {{ user.name }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  Email:
                  </div>
                <div class="col">
                  {{ user.email }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  Created:
                  </div>
                <div class="col">
                  {{ whoAmI._delegate.metadata.creationTime }}
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  Last Sign In:
                  </div>
                <div class="col">
                  {{ whoAmI._delegate.metadata.lastSignInTime }}
                </div>
              </div>
              </div>
              </div>
              </div>
              <div class="card-wrapper">
         <div class="card">
            <div class="card-body">
              <div class="form-group row">
          
                    <label class="col form-control-label">Receive Daily Feedback Summary</label>
                    <div class="col-auto">
                      <base-switch
                        v-model="user.feedbackEmail"
                        type="warning"
                        on-text="Yes"
                        off-text="No"
                        @input="changeField($event, 'feedbackEmail')"
                      ></base-switch>
                    </div>
                  </div>
              </div>
              </div> 
        </div>
      </div>

      <div class="col">
        <div class="card-wrapper">
          <div class="card">
            <div class="card-body">
              <base-button v-if="!hasPassword" block type="default" @click="addPassword"
          >Add a password</base-button
        >

        <base-button v-if="!hasGoogle" block type="default" @click="addGoogle"
          >Link my account with a google login</base-button
        >

        <base-button v-if="!hasMicrosoft" block type="default" @click="addMicrosoft"
          >Link my account with a ECB login</base-button
        >
        </div>
        </div>
        </div>
        <div class="card-wrapper">
          <div class="card">
            <div class="card-body">
              <base-button  v-if="hasPassword" block type="primary" @click="reset"
          >Send Password Reset Email</base-button
        >
        <base-button block type="danger" @click="logout">Logout</base-button>
      </div>
      </div>
      </div>
      </div>
      <!--      
       <div class="col">
        <base-button block @click="whoAmI">whoami</base-button>
      </div>
           <div class="col">
        <base-button block @click="remove">Unlink</base-button>
      </div> -->
    </div>
  </div>
</div>
</template>
<script>
import router from "@/routes/router";
import { Firebase } from '../../../firebase.js'
import {
  Select,
  Option,
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
} from "element-ui";

export default {
  name: "user",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {};
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    users() {
      return this.$store.getters["users/allUsers"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    remove() {
      return this.$store.dispatch("user/unlink", "google.com");
    },
    whoAmI() {
      return Firebase.auth().currentUser
    },
    hasGoogle() {
      const providers = this.whoAmI._delegate.providerData
      return providers.some(obj => obj['providerId'] === 'google.com')
    },
    hasMicrosoft() {
      const providers = this.whoAmI._delegate.providerData
      return providers.some(obj => obj['providerId'] === 'microsoft.com')
    },
    hasPassword() {
      const providers = this.whoAmI._delegate.providerData
      return providers.some(obj => obj['providerId'] === 'password')
    }
  },
  methods: {
    logout() {
      var payload = { path: this.$route.path };
      console.log(payload);
      this.$store.dispatch("user/logOut", payload);
    },
    reset() {
      return this.$store.dispatch("user/passwordReset", this.whoAmI._delegate.email);
    },
    addGoogle() {
      return this.$store.dispatch("user/linkAccountWithGoogle");
    },
    addMicrosoft() {
      return this.$store.dispatch("user/linkAccountWithMicrosoft");
    },
    addPassword() {
      return this.$store.dispatch("user/linkAccountWithPassword", this.whoAmI._delegate.email);
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.user.id;
      payload[field] = value;

        this.$store.dispatch("users/patch", payload);
    },
  },
};
</script>
<style scoped>
</style>
