<template>
  <base-nav
    container-classes="container-fluid"
    class="navbar-top navbar-expand bg-default navbar-dark"
    type=""
  >
    <div class="col">
      <div class="text-white text-center mb-0 jimstaFont" :style="cssProps">
        Jimstagram <badge>Beta</badge>
      </div>
    </div>
    <!-- Navbar links -->
    <ul class="navbar-nav align-items-center ml-md-auto">
      <li class="nav-item d-xl-none">
        <!-- Sidenav toggler -->
        <div
          class="pr-3 sidenav-toggler sidenav-toggler-dark"
          @click="toggleSidebar"
        >
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
            <i class="sidenav-toggler-line"></i>
          </div>
        </div>
      </li>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from "vue2-transitions";
import { BaseNav, Modal } from "@/components";
import router from "@/routes/router";

export default {
  components: {
    CollapseTransition,
    BaseNav,
    Modal,
  },
  computed: {
    isRTL() {
      return this.$rtl.isRTL;
    },
    cssProps() {
      return {
        "--font-name": this.fontName,
      };
    },
  },
  beforeMount() {
        this.$store.dispatch("jimstaServer/openDBChannel");
    const fontSize = this.fonts.length;
    const fontIndex = Math.floor(Math.random() * Math.floor(fontSize));
    const chosen = this.fonts[fontIndex];
    this.fontName = chosen;
    console.log(fontSize);
    console.log(fontIndex);
    console.log(chosen);
    WebFont.load({
      google: {
        families: [this.fontName],
      },
    });
  },
  data() {
    return {
      fontName: "Fontdiner Swanky",
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: "",
      fonts: [
      //   "Fontdiner Swanky",
      //   "Henny Penny",
      //   "Lora",
      //   "Lobster",
      //   "Comfortaa",
      //   "Pacifico",
      //   "Shadows Into Light",
      //   "Abril Fatface",
      //   "Satisfy",
      //   "Kaushan Script",
      //   "Special Elite",
      //   "Monoton",
      //   "Audiowide",
      //   "Faster One",
      //   "Codystar",
      //   "Meddon",
      //   "Euphoria Script",
      //   "Sonsie One",
      //   "Trade Winds",
      //   "Germania One",
      //   "Barriecito",
        "Barrio",
      //   "Beth Ellen",
      //   "Bungee Hairline",
      //   "Bungee Outline",
      //   "Butcherman",
      //   "Caesar Dressing",
      //   "Eater",
      //   "Iceberg",
      //   "Lacquer",
      //   "Lovers Quarrel",
      //   "Nosifer",
      //   "Ruthie",
      //   "Sancreek",
      //   "Sarina",
      //   "Uncial Antiqua",
      ],
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    openBrief() {
      router.push(`/brief/${this.$route.params.prodId}`);
    },
  },
};
</script>
<style>
.top-navbar {
  top: 0px;
}
a.navbar-link {
  text-decoration: underline;
}
.jimstaFont {
  font-family: var(--font-name);
  font-size: 60px;
}
</style>
