const jimstaSlides = {
  firestorePath: 'jimstaSlides',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'jimstaSlides',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    debounceTimerMs: 50
    // defaults to 1000
  },
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    byId: (state) => (id) => {
      return state.data[id]
    },
    byJimsta: (state) => {
      let slides = Object.values(state.data)
      return slides.filter(f => f.jimstaID === id)
    },
    rendering: (state) => {
      let slides = Object.values(state.data)
      return slides.filter(f => f.status === 'rendering')
    },
    html: (state, getters, rootState, rootGetters) => (id) => {

      const slide = state.data[id]

      const activeTemplate = rootGetters["jimstaTemplates/byId"](slide.template);

      console.log(slide)

      let base = activeTemplate.html;
      var el = document.createElement("html");
      el.innerHTML = base;
      // for each slot replace the src with the current slot
      const assets = activeTemplate.details.assets;
      const fields = activeTemplate.details.fields;
      const slots = rootGetters["jimstaSlideSlots/slideSlotInfo"](slide.slots);;
      const inputs = rootGetters["jimstaSlideFields/slideFieldInfo"](slide.fields);;

      var i;
      for (i = 0; i < assets.length; i++) {
        if (assets[i].replaceable) {
          const asset = assets[i];

          let id = asset.id;
          let image = el.getElementsByClassName(id);

          let slot = slots[asset.replaceableOrder];
          if (slot.fileID) {
            let fileID = slot.fileID;
            let fileData = rootGetters["files/fileById"](fileID);
            if(fileData.sourceDownloadURL){
            image[0].src = fileData.sourceDownloadURL;
            }
          }
        }
      }

      var j;
      for (j = 0; j < fields.length; j++) {
        if (fields[j].editable) {
          const field = fields[j];
          let id = field.id;
          let div = el.getElementsByClassName(id);
          let input = inputs[field.editableOrder];
          if (field.type === "text") {
            div[0].innerHTML = input.value;
          } else if (field.type === "link") {
            div[0].href = 'https://' + input.value;
          }
        }
      }
      return el.innerHTML;
    }
  },
  mutations: {},
  actions: {
    clearTemplate({ dispatch, getters }, payload) {
      // this function just sets extra slots and all fields to overlimit status, so they don't show in front end. it doesn't delete them so if the users switches back, the slots are still there for them with the assets and fields populated.
      let slide = getters["byId"](payload.id);
      let slots = slide.slots;
      let fields = slide.fields;
      var i, j;
      // if more slots than 1, set all the extra slots to over the limit
      if (slots.length > 1) {
        for (i = 1; i <= slots.length; i++) {
          let slotPayload = {};
          slotPayload.overlimit = true;
          slotPayload.id = slots[i];
          dispatch("jimstaSlideSlots/patch", slotPayload, { root: true });
        }
      }
      // set all fields as overlimit
      for (j = 0; j < fields.length; j++) {
        let fieldPayload = {};
        fieldPayload.overlimit = true;
        fieldPayload.id = fields[j];
        dispatch("jimstaSlideFields/patch", fieldPayload, { root: true });
      }

      payload.template = ""; // set template to empty
      dispatch("patch", payload);
    },
    changeTemplate({ dispatch, getters }, payload) {
      const slideID = payload.id
      const template = payload.template
      let slide = getters["byId"](slideID);
      let slots = slide.slots;
      const jimstaID = slide.jimstaID

      let replaceables = 0
      if (template.details.replaceableAssets) {
        replaceables = template.details.replaceableAssets
      }

      var i, j;
      for (i = 0; i < slots.length; i++) {
        if (i < replaceables) {
          // less than max
          let slotPayload = {};
          slotPayload.id = slots[i];
          slotPayload.overlimit = false;
          console.log(slotPayload);
          dispatch("jimstaSlideSlots/patch", slotPayload, { root: true });
        }
        if (i >= replaceables) {
          // more than max
          let slotPayload = {};
          slotPayload.id = slots[i];
          slotPayload.overlimit = true;
          console.log(slotPayload);
          dispatch("jimstaSlideSlots/patch", slotPayload, { root: true });
        }
      }

      if (slots.length < replaceables) {
        //  if number of slots is less than the template, create extra
        console.log("less slots than template");
        const numToMake = replaceables - slots.length;
        for (j = 0; j < numToMake; j++) {
          dispatch("jimstaSlideSlots/addSlot", {
            slideID: slideID,
            jimstaID: jimstaID,
          }, { root: true });
        }
      }

      let fields = slide.fields;
      var i, j;
      for (i = 0; i < fields.length; i++) {
        // if number of fields is more than the template, flag as over the limit
        if (i < template.details.editableFields) {
          let fieldPayload = {};
          fieldPayload.id = fields[i];
          fieldPayload.overlimit = false;
          dispatch("jimstaSlideFields/patch", fieldPayload, { root: true });
        }
        if (i >= template.details.editableFields) {
          let fieldPayload = {};
          fieldPayload.id = fields[i];
          fieldPayload.overlimit = true;
          dispatch("jimstaSlideFields/patch", fieldPayload, { root: true });
        }
      }

      if (fields.length < template.details.editableFields) {
        //  if number of fields is less than the template, create extra
        console.log("less fields than template");
        const numToMake = template.details.editableFields - fields.length;
        for (j = 0; j < numToMake; j++) {
          dispatch("jimstaSlideFields/addField", {
            slideID: slideID,
            jimstaID: jimstaID,
          }, { root: true });
        }
      }

      let slidePayload = {};
      slidePayload.id = slideID;
      slidePayload.template = template.id;
      dispatch("patch", slidePayload);
    },
    onChangeJimstaSetting({ dispatch, getters, rootGetters }, payload) { // if dimension, brand or jimsta type changes, we need to find a template from the same family or clear graphics it if none present
      const slide = getters["byId"](payload.slide);
      const dimensions = payload.dimensions
      const brand = payload.brand
      const jimstaType = payload.jimstaType

      var newPayload = {};
      newPayload.id = slide.id;



      //delete the render file if one is present
      if (slide.status === "rendered") {
        dispatch("files/delete", slide.render, { root: true });
        //set slide back to edit mode
        newPayload.status = "edit";
        dispatch("patch", newPayload);
      }

      // grab details about the template
      const templateInfo = rootGetters[
        "jimstaTemplates/byId"
      ](slide.template);
      console.log(templateInfo)

      // grab the templates in the same family as current one
      const templatesInTheSameFamily = rootGetters[
        "jimstaTemplates/byFamily"
      ](templateInfo.family);
      let temps = templatesInTheSameFamily;

      // find if a template with the same family name but matches with the new dimensions, brand and type
      if (dimensions !== "") {
        temps = temps.filter((p) => p.dimensions === dimensions);
      }
      if (brand !== "") {
        temps = temps.filter((p) => p.brand === brand);
      }
      if (jimstaType !== "") {
        temps = temps.filter((p) => p.type === jimstaType);
      }
      let newTemp;
      if (temps.length > 0) {
        // fire the action to change the template to the first template it finds
        newTemp = temps[0];
        newPayload.template = newTemp;
        dispatch("changeTemplate", newPayload);
      } else {
        // fire the action to clear the template
        dispatch("clearTemplate", { id: slide.id });
      }
    },
    deleteSlide({ dispatch, getters }, payload) {

    }
  },
}

export default jimstaSlides