<template>
  <div>
    <div>{{ match.team1Name }} vs {{ match.team2Name }}</div>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["matchID"],
  computed: {
    match() {
      var id = this.$store.getters["matches/byId"](this.matchID);
      if (id) {
        return id;
      } else {
        this.$store.dispatch("matches/fetchById", this.matchID);
      }
    },
  },
  components: {},
  mounted() {},
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>