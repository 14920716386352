<template>
  <div>
    <div class="row">
      <div class="col-4">
        <file-preview
          :file="file"
          v-if="file.hasPreview && file.metadata"
        ></file-preview>
      </div>
      <div class="col-2">
        <div class="mb-3">{{ file.sourceFileName }}</div>

        <div v-if="file.metadata">
          Time Start: {{ file.metadata.timecodeStart }}

          <div v-if="!file.hasPreview">
            <base-button size="sm" @click="preview()"
              >Generate Preview</base-button
            >
          </div>
          <base-button
            v-else
            size="sm"
            class="mt-3"
            type="danger"
            @click="preview()"
            >Regenerate Preview</base-button
          >
        </div>
        <div v-else>
          No timecode!
          <base-button size="sm" @click="metadata()"
            >Generate Metadata</base-button
          >
        </div>
      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <base-input label="Match">
              <el-select filterable :placeholder="Select" v-model="match">
                <el-option
                  v-for="option in matches"
                  :key="option.id"
                  :label="option.selectedLabel"
                  :value="option.id"
                >
                  <span style="float: left" class="mr-2">
                    {{ option.startDate | dateTimeArchiveAssociation }}
                  </span>
                  <span style="float: left">{{ option.label }}</span>
                </el-option>
              </el-select>
            </base-input>
          </div>
          <div class="col">
            <base-input label="Day" type="number" v-model="day" />
          </div>
          <div class="col">
            <base-input label="Part" type="number" v-model="part" />
          </div>
        </div>
      </div>
      <div class="col-2" v-if="file.hasPreview && match">
        <base-button
          v-if="file.metadata"
          size="sm"
          type="info"
          @click="pair('recording', true)"
          >Pair As Full Recording - TV</base-button
        >
        <base-button
          v-if="file.metadata"
          size="sm"
          class="mt-3"
          type="primary"
          @click="pair('recording', false)"
          >Pair As Full Recording - Non-TV</base-button
        >
        <base-button
          size="sm"
          class="mt-3"
          type="info"
          @click="pair('match-reel', true)"
          >Pair As Highlights - TV</base-button
        >
        <base-button
          size="sm"
          class="mt-3"
          type="primary"
          @click="pair('match-reel', false)"
          >Pair As Highlights - Non-TV</base-button
        >
      </div>
     <!-- <div class="col-2">
           <base-button size="sm" type="danger" class="mt-3" @click="deleteFile()"
          >Delete</base-button
        >
      </div> -->
      <div class="col-2">
        <base-button size="sm" type="success" class="mt-3" @click="find()"
          >Find with Date</base-button
        >
        <base-button size="sm" type="success" class="mt-3" @click="findEllipse()"
          >Find with Ellipse ID</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Firebase } from "@/firebase";
import moment from "moment";

export default {
  data() {
    return {
      match: "",
      day: 1,
      part: 1,
    };
  },
  props: ["file"],
  computed: {
    // goToAssets() {
    //   return `/#/archive/video/${this.clip.id}`;
    // },
    // match() {
    //   return this.$store.getters["matches/byId"](this.match.id);
    // },
    matches() {
      return this.$store.getters["matches/selectQuery"];
    },
  },
  components: {},
  methods: {
    findEllipse() {


      if (this.file.sourceFileName.toLowerCase().indexOf("part") > 1) {
        let partIndex = this.file.sourceFileName.toLowerCase().indexOf("part");

        let f = this.file.sourceFileName

        let part = f.substring(partIndex + 4, partIndex + 5)
        console.log(part)
        console.log('matched')
        this.part = part
      }

      if (this.file.sourceFileName.toLowerCase().indexOf("part_") > 1) {
        let partIndex = this.file.sourceFileName.toLowerCase().indexOf("part_");

        let f = this.file.sourceFileName
        let part = f.substring(partIndex + 5, partIndex + 6)
        console.log(part)
        console.log('matched_')
        this.part = part
      }

      if (this.file.sourceFileName.toLowerCase().indexOf("day") > 1) {
        let dayIndex = this.file.sourceFileName.toLowerCase().indexOf("day");

        let f = this.file.sourceFileName

        let day = f.substring(dayIndex + 3, dayIndex + 4)
        this.day = day
      }

      if (this.file.sourceFileName.toLowerCase().indexOf("day_") > 1) {
        let dayIndex = this.file.sourceFileName.toLowerCase().indexOf("day_");

        let f = this.file.sourceFileName

        let day = f.substring(dayIndex + 4, dayIndex + 5)
        this.day = day
      }


      let id = this.file.sourceFileName.slice(0, 6);

      this.matches.forEach((element) => {
        let thisId = element.ellipseMatchId;
        if (thisId === id) {
          //exact match
          this.match = element.id;
        }
      });

    },
    find() {


      if (this.file.sourceFileName.toLowerCase().indexOf("Part_")) {
        let partIndex = this.file.sourceFileName.toLowerCase().indexOf("Part_");

        let f = this.file.sourceFileName
        let part = f.substring(partIndex + 5, partIndex + 6)
        this.part = part
      }

      if (this.file.sourceFileName.toLowerCase().indexOf("Part")) {
        let partIndex = this.file.sourceFileName.toLowerCase().indexOf("Part");

        let f = this.file.sourceFileName
        let part = f.substring(partIndex + 4, partIndex + 5)
        this.part = part
      }

      

      let d = this.file.sourceFileName.slice(0, 10);
      // console.log(d);
      let res = false;

      // go through matches and find the right match
      this.matches.forEach((element) => {
        let thisD = element.startDate;
        thisD = moment(thisD).format("YYYY-MM-DD");

        if (thisD === d) {
          //exact match
          this.match = element.id;
          res = true;
        }
      });

      if (res) {
        return "found";
      } else {
        // find nearest match

        let fileD = moment(d, "YYYY-MM-DD").startOf("day");
        this.matches.forEach((element) => {
          let thisD = moment(element.startDate).startOf("day");
          let diff = fileD.diff(thisD, "days") + 1;

          if (diff > 1 && diff < 6) {
            // console.log(fileD.format("DD-MM-YY"));
            // console.log(thisD.format("DD-MM-YY"));
            // console.log(diff);
            this.match = element.id;
            this.day = diff;
          }
        });
        return "found";
      }
    },
    pair(type, tv) {
      var pairing = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("archivePaired");
      // console.log(payload)
      pairing({
        fileID: this.file.id,
        matchID: this.match,
        day: this.day,
        type: type,
      });
      this.$store.dispatch("files/patch", {
        id: this.file.id,
        match: this.match,
        part: this.part,
        day: this.day,
        what: type,
      });
      this.$store.dispatch("matches/patch", {
        id: this.match,
        tv: tv,
      });
    },
    metadata() {
      var metadata = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("metadataOnRequest");
      metadata(this.file);
    },
    preview() {
      var metadata = Firebase.app()
        .functions("europe-west2")
        .httpsCallable("generatePreviewRequest");
      // console.log(payload)
      metadata(this.file);
    },
    deleteFile() {
      this.$store.dispatch("files/delete", this.file.id);
    },
  },
};
</script>

<style scoped>
/*
.card{
  position: inherit;
} */
</style>
