import { arrayUnion, arrayRemove } from "vuex-easy-firestore";

const jimstas = {
  firestorePath: 'jimstas',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'jimstas',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    debounceTimerMs: 50
    // defaults to 1000
  },
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },
  // you can also add state/getters/mutations/actions
  state: {
  },
  getters: {
    jimstas: (state) => {
      return Object.values(state.data)
    },
    byId: (state) => (id) => {
      return state.data[id]
    },
    building: (state) => {
      let jimstas = Object.values(state.data)
      return jimstas.filter(f => f.status === 'building')
    },
    modelById: (state, getters, rootState) => (id) => {
      var jimsta = state.data[id]
      var slides = jimsta.slides.slice()

      var i;
      for (i = 0; i < slides.length; i++) {
        var slideID = slides[i]

        let thisSlide = rootState.jimstaSlides.data[slideID]

        if (thisSlide) {
          let newSlideSlots = [];
    
          for (var j = 0; j < thisSlide.slots.length; j++) {
            let thisSlot = {
              ...rootState.jimstaSlideSlots.data[thisSlide.slots[j]],
            };

            //add file info into this
            if (thisSlot.fileID) {
              thisSlot.file = {
                ...rootState.files.data[thisSlot.fileID],
              };
            }

            newSlideSlots.push(thisSlot);
          }
          thisSlide.slotInfo = newSlideSlots;
        }


       
        slides[i] = thisSlide;
      }

      return slides
    },
  },
  mutations: {},
  actions: {
    newSlide({ dispatch }, payload) {
      const slide = {
        jimstaID: payload.jimstaID,
        status: "edit",
        fields: [],
        slots: []
      };
      //create the slide
      dispatch("jimstaSlides/insert", slide, { root: true }).then((slideID) => {
        // update jimsta db with the slideID
        dispatch("patch", {
          id: payload.jimstaID,
          slides: arrayUnion(slideID),
        });
        //create the first asset slot
        dispatch("jimstaSlideSlots/insert", { jimstaID: payload.jimstaID, slideID: slideID, overlimit: false }, { root: true }).then((slotID) => {
          // and then update the slide db with the slot ID
          dispatch("jimstaSlides/patch", { id: slideID, slots: arrayUnion(slotID) }, { root: true })
          // if slide is invoked via a fileUpload
          if (payload.filePayload) {
            payload.filePayload.what = "jimsta-asset";
            payload.filePayload.jimstaID = payload.jimstaID;
            payload.filePayload.slideID = slideID
            // create the file
            console.log(payload.filePayload)
            dispatch("files/insert", payload.filePayload, { root: true }).then((fileID) => {
              // and update the slot db with the dile
              dispatch("jimstaSlideSlots/patch", { id: slotID, fileID: fileID }, { root: true })


            })
          }
        })
      })
    },
  }
}
export default jimstas