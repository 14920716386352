<template>
  <div>
    <file-preview :file="file"></file-preview>

    <div v-if="file.name" class="mt-1 mb-3 wrap">{{ file.name }}</div>
    <div v-else class="mt-1 mb-2 wrap">{{ file.sourceFileName }}</div>
    <div v-if="file.metadata">
      Duration: {{ file.metadata.duration | duration }} |
      {{ file.metadata.size | fileSize }}
    </div>

    <small>{{ file.created_at | dateAndTime }}</small>

    <div v-if="file.day" class="mt-1 mb-3 wrap">Day: {{ file.day }}</div>
      <div v-if="file.part > 1" class="mt-2 mb-3 wrap">Part: {{ file.part }}</div>

    <div class="mt-2" v-if="file.label">
      <base-input
        label="Label"
        v-model="file.label"
        placeholder="Add a label"
        @change="changeField($event.target.value, 'label')"
      />
    </div>

    <div class="mt-3">
      <a download class="btn btn-sm" :href="file.sourceDownloadURL">
        <i class="fa fa-download mr-1"></i>Download
      </a>
            <!-- <a class="btn btn-sm" :href="'/archive/recording/' + file.id">
        <i class="fa fa-cut mr-1"></i>Clipping
      </a> -->

          <router-link class="btn btn-sm" :to="'/recording/' + file.id"><i class="fa fa-cut mr-1"></i>Clip</router-link>
      <!-- <div
        class="btn btn-sm"
        @click="sendToBC"
        v-if="file.day && user.roles.archive_uploader"
      >
        <i class="fa fa-paper-plane mr-1"></i>Brightcove
      </div> -->
      <!-- <base-button
        v-if="user.roles.archive_uploader"
        size="sm"
        type="danger"
        @click="deleteHighlight()"
        >Delete</base-button
      > -->
    </div>

    <!-- <div
        class="btn btn-sm"
        @click="pair"

      >
       Re-pair
      </div> -->
  </div>
</template>

<script>
import router from "@/routes/router";

// import { Firebase } from "@/firebase";

// js import
import swal from "sweetalert2";

// style import
import "sweetalert2/dist/sweetalert2.css";


export default {
  data() {
    return {};
  },
  props: ["file", "matchData"],
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    fileID() {
      return this.file.id;
    },
  },
  components: {},
  methods: {
    // pair(type) {
    //     var pairing = Firebase.app()
    //       .functions("europe-west2")
    //       .httpsCallable("archivePaired");
    //     // console.log(payload)
    //     pairing({
    //       fileID: this.file.id,
    //       matchID: this.matchData.id,
    //       day: this.day,
    //       type: type
    //     });
    //   },

    deleteHighlight() {
      swal
        .fire({
          title: "Are you sure?",
          text: "Not undo-able.",
          type: "warning",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#f5365c",
          cancelButtonColor: "#172b4d",
          confirmButtonText: "Yes, delete it.",
        })
        .then((result) => {
          if (result.value) {
            this.$store.dispatch("files/delete", this.fileID);
          }
        });
    },
    changeField(value, field) {
      var payload = {};
      payload.id = this.file.id;
      payload[field] = value;
      this.$store.dispatch("files/patch", payload);
    },
    sendToBC() {
      const data = {
        uploadName: `Highlights | ${this.matchData.label} - Day ${this.file.day} - ${this.matchData.competitionName}`,
        file: {
          sourceBucket: this.file.sourceBucket,
          sourcePath: this.file.sourcePath,
        },
      };
      this.$store.dispatch("files/sendToBC", data);
      this.$notify({
        message: "Submitted",
        timeout: 4000,
        type: "default",
        overlap: true,
        verticalAlign: "bottom",
        horizontalAlign: "right",
      });
    },
  },
};
</script>

<style scoped>
.wrap{
  white-space:pre-wrap
}
/* 
.card{
  position: inherit;
} */
</style>