<template>
  <div>
    <base-header class="pb-9 pt-3 mt-0" type="danger">
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3>Query Builder</h3>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center">
                <div class="col">
                  <base-input>
                    <el-select
                      v-model="filters"
                      filterable
                      multiple
                      placeholder="Add filters"
                    >
                      <el-option
                        v-for="option in filterOptionArray"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
                <div class="col-auto" v-if="filters.length > 0">
                  <base-input>
                    <base-button @click="runQuery">Fetch</base-button>
                  </base-input>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-4" v-for="filter in filters" :key="filter">
                  <filter-selector
                    :selection="filterOptions[filter]"
                    :preValue="filterValues[filter]"
                    @valueChange="filterValueChange($event, filter)"
                  >
                  </filter-selector>
                </div>
              </div>
              <div class="row align-items-center">
                <!-- <div class="col-4">
                  <base-input label="Display Mode">
                    <el-select
                      v-model="displayMode"
                      placeholder="Display Options"
                    >
                      <el-option
                        v-for="option in displayModes"
                        :key="option"
                        :label="option"
                        :value="option"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div> -->

                <!-- <div class="col-6">
                  <base-input label="Delivery Fields">
                    <el-select
                      v-model="displayFields"
                      @change="displayFieldsSet($event)"
                      filterable
                      multiple
                      placeholder="Delivery Criteria"
                    >
                      <el-option
                        v-for="option in displayOptions"
                        :key="option.field"
                        :label="option.label"
                        :value="option.field"
                      >
                      </el-option>
                    </el-select>
                  </base-input>
                </div> -->
              </div>
            </div>
          </div>
          <div class="card" v-if="selected.length > 0">
            <div class="card-header">
              <h3>Selection</h3>
            </div>
            <div class="card-body mb--4">
              <div class="row align-items-center mb-3">
                <div class="col">{{ selected.length }} clips selected</div>
                <!-- <div class="col-auto">
                  <a href="#/collections" class="btn btn-default"
                    >Add to a Collection</a
                  >
                  <base-button @click="clearSelection" type="secondary"
                    >Clear Selection</base-button
                  >
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--9">
      <div class="row">
        <div class="col">
          <card
            class="no-border-card"
            body-classes="px-0 py-0"
            footer-classes="pb-2"
          >
            <el-table
              class="matchesTable"
              :data="clips"
              row-key="id"
              header-row-class-name="thead-light"
            >
     
              <el-table-column label="Clip" min-width="150">
                <template v-slot="{ row }">
                  <file-preview :key="row._source.id" :fileID="row._source.clipFile"></file-preview>
                </template>
              </el-table-column>
              <el-table-column width="50">
                <template v-slot="{ row }">
                  <download :fileID="row._source.clipFile"></download>

                  <a
                    class="btn btn-sm"
                    :href="`/#/recording/${row._source.file}/${row._source.id}`"
                    ><i class="ni ni-curved-next"></i
                  ></a>
                </template>
              </el-table-column>
              <el-table-column label="Tags">
                <template v-slot="{ row }">
                  {{ row._source.bookmark }}
                  <player-look-up
                    :id="row._source.bookmarkPlayer"
                  ></player-look-up>
                  <team-look-up :id="row._source.bookmarkTeam"></team-look-up>
                </template>
              </el-table-column>
              <el-table-column label="Note" prop="_source.note">
              </el-table-column>
              <el-table-column label="Match" prop="_source.match">
                <template v-slot="{ row }">
                  <match-look-up :matchID="row._source.match"></match-look-up>

                  {{ row._source.date }}

                  <venue-look-up :venueID="row._source.ground"></venue-look-up>
               
                  <format-look-up
                    :formatID="row._source.cricketFormat"
                  ></format-look-up>
                </template>
              </el-table-column>

              <el-table-column
                label="Clipped At"
                prop="_source.clipped_at._seconds"
  
              >
                <template v-slot="{ row }">
                  {{ row._source.clipped_at | elasticsearchDate }}
                </template>
              </el-table-column>
              <el-table-column label="Clipped By" prop="_source.clippedBy">
                <template v-slot="{ row }">
                  <user-name :id="row._source.clippedBy"></user-name>
                </template>
              </el-table-column>
            </el-table>
          </card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveClip from "./Components/ArchiveClip.vue";
import FilterSelector from "./Components/FilterSelector";
import VenueLookUp from "./Components/VenueLookUp";
import MatchLookUp from "./Components/MatchLookUp";
import TypeLookUp from "./Components/TypeLookUp";
import FormatLookUp from "./Components/FormatLookUp";
import PlayerLookUp from "./Components/PlayerLookUp";
import TeamLookUp from "./Components/TeamLookUp";
import Download from "./Components/Download";
import UserName from "@/echoComponents/UserName";
import { Firebase } from "@/firebase";



export default {
  name: "clip-search",
  components: {
    "archive-clip": ArchiveClip,
    "filter-selector": FilterSelector,
    "match-look-up": MatchLookUp,
    "venue-look-up": VenueLookUp,
    "type-look-up": TypeLookUp,
    "format-look-up": FormatLookUp,
    "player-look-up": PlayerLookUp,
    "team-look-up": TeamLookUp,
    download: Download,
    "user-name": UserName,

  },
  data() {
    return {
      displayModes: ["Table", "Cards"],
      displayMode: "Cards",
      clips: [],
      sortProp: "clipped_at._seconds",
      sortOrder: "desc",
      filterOptions: {
        // startDate: { field: "startDate", label: "Match Start", type: "date-range" },
        bookmark: {
          field: "bookmark",
          label: "Tag",
          collection: "archiveTemp",
          collectionIsArray: true,
          collectionGetter: "bookmarkOptions",
          type: "single-select",
        },
        bookmarkPlayer: {
          field: "bookmarkPlayer",
          index: "players",
          searchField: "fullName",
          label: "Tagged Player",
          type: "single-search",
        },
        bookmarkTeam: {
          field: "bookmarkTeam",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Tagged Team",
          type: "single-select",
        },
        clippedBy: {
          field: "clippedBy",
          label: "Clipped By",
          collection: "users",
          collectionLabel: "name",
          collectionGetter: "users",
          type: "single-select",
        },
        note: {
          field: "note",
          label: "Note Search",
          type: "text",
        },
        year: {
          field: "year",
          label: "Year",
          type: "number",
        },
        cricketFormat: {
          field: "cricketFormat",
          label: "Format",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "formats",
          type: "single-select",
        },
        cricketType: {
          field: "cricketType",
          label: "Type",
          collection: "matchFormats",
          collectionLabel: "name",
          collectionGetter: "types",
          type: "single-select",
        },
        batTeam: {
          field: "batTeam",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Batting Team",
          type: "single-select",
        },
        bowlTeam: {
          field: "bowlTeam",
          collection: "teams",
          collectionLabel: "teamName",
          label: "Bowling Team",
          type: "single-select",
        },
        batter: {
          field: "batter",
          index: "players",
          searchField: "fullName",
          label: "Batter",
          type: "single-search",
        },
        bowler: {
          field: "bowler",
          index: "players",
          searchField: "fullName",
          label: "Bowler",
          type: "single-search",
        },
        ground: {
          field: "ground",
          collection: "venues",
          collectionLabel: "name",
          label: "Venue",
          type: "single-select",
        },
        wicket: {
          field: "wicket",
          label: "Wicket",
          type: "boolean",
        },
        runs: {
          field: "runs",
          label: "Runs",
          type: "number",
        },
        bowlLength: {
          field: "bowlLength",
          label: "Bowling Length",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlLength",
          type: "single-select",
        },
        bowlLine: {
          field: "bowlLine",
          label: "Bowling Line",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlLine",
          type: "single-select",
        },
        bowlMove: {
          field: "bowlMove",
          label: "Bowling Movement",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlMove",
          type: "single-select",
        },
        bowlStyle: {
          field: "bowlStyle",
          label: "Bowling Style",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "bowlStyle",
          type: "single-select",
        },
        fieldAct: {
          field: "fieldAct",
          label: "Fielder Action",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "fieldAct",
          type: "single-select",
        },
        fieldPos: {
          field: "fieldPos",
          label: "Fielder Position",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "fieldPos",
          type: "single-select",
        },
        footMove: {
          field: "footMove",
          label: "Foot Movement",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "footMove",
          type: "single-select",
        },
        howOut: {
          field: "howOut",
          label: "How Out",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "howOut",
          type: "single-select",
        },
        shotType: {
          field: "shotType",
          label: "Shot Type",
          collection: "matchFormats",
          collectionIsArray: true,
          collectionGetter: "shotType",
          type: "single-select",
        },
      },
      pagination: {
        default: 1,
        chunkSize: 25,
      },
    };
  },
  beforeMount() {
    this.$store.dispatch("teams/fetchAndAdd");
    this.$store.dispatch("players/fetchAndAdd");
    this.$store.dispatch("venues/fetchAndAdd");
    // this.$store.commit("archiveEvents/RESET_VUEX_EASY_FIRESTORE_STATE");
    this.$store.dispatch("users/openDBChannel");
    this.runQuery();
  },
  computed: {
    config() {
      return this.$store.getters["config/config"];
    },
    loading() {
      return this.$store.getters["user/loading"];
    },
    user() {
      return this.$store.getters["user/user"];
    },
    filters: {
      // getter
      get: function () {
        return this.$store.getters["archiveTemp/clipSearchFilters"];
      },
      // setter
      set: function (newValue) {
        this.$store.commit("archiveTemp/clipSearchFilters", newValue);
      },
    },
    filterValues() {
      return this.$store.getters["archiveTemp/clipSearchValues"];
    },
    matches() {
      return this.$store.getters["matches/array"];
    },
    teams() {
      return this.$store.getters["teams/array"];
    },
    venues() {
      return this.$store.getters["venues/array"];
    },
    matchFormats() {
      return this.$store.getters["matchFormats/array"];
    },
    // clips() {
    //   return this.$store.getters["archiveEvents/array"];
    // },
    matchDisplayFields() {
      return this.$store.getters["archiveTemp/matchDisplayFields"];
    },
    matchDisplayOptions() {
      return this.$store.getters["archiveTemp/matchDisplayOptions"];
    },
    displayFields() {
      return this.$store.getters["archiveTemp/displayFields"];
    },
    displayOptions() {
      return this.$store.getters["archiveTemp/displayOptions"];
    },
    filterOptionArray() {
      return Object.values(this.filterOptions);
    },
    selected() {
      return this.$store.getters["archiveTemp/eventSelection"];
    },
  },
  watch: {
    clips: function (newOne, old) {
      let files = {};
      let players = {};
      newOne.forEach((clip) => {
        // console.log(clip.file);
        clip = clip._source;
        if (clip.bookmarkPlayer) players[clip.bookmarkPlayer] = true;
        if (clip.clipFile) {
          files[clip.clipFile] = true;
        }
      });
      // load the files
      files = Object.keys(files);
      console.log(files);
      files.forEach((file) => {
        let res = this.$store.getters["files/fileById"](file);
        if (res === undefined) {
          this.$store.dispatch("files/fetchById", file);
        }
      });
      // load the players
      players = Object.keys(players);
      console.log(players);
      players.forEach((player) => {
        let res = this.$store.getters["players/byId"](player);
        if (res === undefined) {
          this.$store.dispatch("players/fetchById", player);
        }
      });
    },
  },
  methods: {
    async runQuery() {
      //this.$store.commit("archiveEvents/RESET_VUEX_EASY_FIRESTORE_STATE");
      let filters = this.filters;
      let payload = {
        response: null,
        request: {
          index: "clips",
          body: {
             sort: [
             {

},
            ],
            from: 0,
            size: 100,
            query: {
              bool: {
                must: [],
                should: [],
                filter: [],
              },
            },
          },
        },
      };
      payload.request.body.sort[0][this.sortProp] = {
        order: this.sortOrder,
      }

      filters.forEach((field) => {
        if (this.filterValues[field]) {
          // check to make sure the filter has a value
          const value = this.filterValues[field];
          const option = this.filterOptions[field];
          let req = {};
          if (
            option.type === "single-select" ||
            option.type === "single-search" ||
            option.type === "boolean"
          ) {
            req.match = {};
            req.match[field] = value;
          } else if (option.type === "multi-select") {
            value.forEach((v) => {
              const f = `${field}.${v}`;
              req.match = {};
              req.match[field] = true;
            });
          } else if (option.type === "number") {
            req.match = {};
            req.match[field] = parseInt(value);
          } else if (option.type === "text") {
            req.match = {};
            req.match[field] = { query: value, fuzziness: "AUTO" };
          }
          payload.request.body.query.bool.filter.push(req);
        }
      });

      // const orderBy = ['created_at', 'desc']

      // this.$store.dispatch("archiveEvents/fetchAndAdd", {
      //   clauses: { where, limit: 100 },
      // });


      // this.$store.commit("user/setLoading", true);
      const result = await Firebase.firestore()
        .collection("search")
        .add(payload);
      console.log(result.id);

      Firebase.firestore()
        .collection("search")
        .doc(result.id)
        .onSnapshot((doc) => {
          let d = doc.data();
          if (d.response !== null) {
            // Do things
            console.log(d);
            this.clips = d.response.hits.hits;
            // this.searchHits = d.response.hits.total.value;
            // this.searchReturn = d.response.hits.hits.length;
            // this.pagination.to = this.pagination.from + this.searchReturn;
            // this.searchPages = Math.ceil(
            //   this.searchHits / this.pagination.chunkSize
            // );
            // this.$store.commit("user/setLoading", false);
            this.freshSearch = false;
          }
        });
    },
    clearSelection() {
      this.$store.commit("archiveTemp/clearSelection");
    },
    displayFieldsSet(value) {
      this.$store.commit("archiveTemp/displayFields", value);
    },
    filterValueChange(value, filter) {
      this.$store.commit("archiveTemp/clipSearchValues", {
        value: value,
        filter: filter,
      });
    },
    matchDisplayFieldsSet(value) {
      this.$store.commit("archiveTemp/matchDisplayFields", value);
    },
  },
};
</script>

<style>
</style>