import { arrayUnion, arrayRemove } from "vuex-easy-firestore";

const jimstaSlideFields = {
  firestorePath: 'jimstaSlideFields',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'jimstaSlideFields',
  statePropName: 'data',
  namespaced: true, // automatically added
  sync: {
    debounceTimerMs: 100
    // defaults to 1000
  },

  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    byId: (state) => (id) => {
      return state.data[id]
    },
    bySlideId: (state) => (id) => {
      let slots = Object.values(state.data)
      return slots.filter(f => f.slideID === id)
    },
    slideFieldInfo: (state) => (fieldList) => {
      let fields = [];

      for (var j = 0; j < fieldList.length; j++) {

        fields.push({
          ...state.data[
          fieldList[j]
          ]

        });
      }
      return fields

    },
    isFieldValid: (state) => (id) => {
      const slotData = state.data[id]
      if (!slotData.value && !slotData.overlimit) {
        return false
      } else {
        return true
      }
    },
    byJimstaId: (state) => (id) => {
      let slots = Object.values(state.data)
      return slots.filter(f => f.jimstaID === id)
    },
  },
  mutations: {},
  actions: {
    addField({ dispatch }, payload) {
      dispatch("insert", {
        overlimit: false,
        slideID: payload.slideID,
        jimstaID: payload.jimstaID,
      }).then(fieldID => {
        dispatch("jimstaSlides/patch", { id: payload.slideID, fields: arrayUnion(fieldID) }, { root: true })
      })
    },
  }

}

export default jimstaSlideFields