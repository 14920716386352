const jimstaTemplates = {
  firestorePath: 'jimstaTemplates',
  firestoreRefType: 'collection', // or 'doc'
  moduleName: 'jimstaTemplates',
  statePropName: 'data',
  namespaced: true, // automatically added
  serverChange: {
    convertTimestamps: {
      created_at: '%convertTimestamp%', // default
      updated_at: '%convertTimestamp%', // default
      // define each field like so ↑ in an object here
    },
  },
  // you can also add state/getters/mutations/actions
  state: {},
  getters: {
    templates: (state) => {
      const temps = Object.values(state.data)
      return temps.filter(temp => temp.status === 'assessed')
    },
    awaiting: (state) => {
      const temps = Object.values(state.data)
      return temps.filter(temp => temp.status === 'Awaiting Assessment')
    },
    thumbnailRenders: (state) => {
      const temps = Object.values(state.data)
      return temps.filter(temp => temp.thumbnailStatus === 'rendering')
    },
    byId: (state) => (id) => {
      return state.data[id]
    },
    byFamily: (state) => (family) => {
      const temps = Object.values(state.data)
      return temps.filter(temp => temp.family === family)
    },
    // allFamilies: (state) => {
    //   let arr = [];
    //   const temps = Object.values(state.data)
    //   temps.forEach(function (template) {
    //     console.log(template)
    //     if (template.family) {
    //       arr.push(template.family)
    //     }
    //   });
    //   return arr
    // },
    familiesList: (state) => {
      let arr = {};
      const temps = Object.values(state.data)
      temps.forEach(function (template) {
        if (template.family) {
          arr[template.family] = true
        }
      });
      return Object.keys(arr).sort((a, b) => {
        if (a < b) {
          return -1
        }
        if (a > b) {
          return 1
        }
        if (!a) {
          return -1
        }
        return 0
      })
    }
  },
  mutations: {},
  actions: {
  },
}

export default jimstaTemplates