<template>
  <div>
    <base-header class="pb-9" type="danger"> </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header">
              <div class="h3 mb-0">{{ match.team1Name }} vs {{ match.team2Name }}</div>
            </div>
            <div class="card-body">
              <div class="row">

                <div class="col">
                  {{ venue.name }}
                </div>
                <div class="col">
                  {{ format.name }}
                </div>
                <div class="col">
                  {{ match.startDate | dateTime }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <tabs tabNavClasses="nav-pills" type="danger">
            <tab type="danger">
              <span slot="title">
                Highlights
              </span>
              <div class="card shadow mt-3">

                <div class="card-body">
                  <div class="row mb-5" v-if="user.roles.archive_uploader">
                    <div class="col">
                      <archive-upload-wrapper boxHeight="90px" :match="match.id"></archive-upload-wrapper>
                    </div>
                  </div>
                  <div class="row">
                    <div v-for="(reel, index) in reels" :key="index" class="col-4 mb-4">

                      <reel-card :file="reel" :matchData="match"> </reel-card>
                    </div>
                  </div>
                </div>
              </div>
            </tab>
            <tab v-if="streamClips.length > 0">
              <span slot="title">
                Stream Clips
              </span>

              <div class="card shadow mt-3">
                <div class="card-header">
                  <div class="row align-items-center">
                    <div class="col-4">
                      <base-input class="select-danger">
                        <el-select v-model="inningsFilter" clearable placeholder="Innings">
                          <el-option v-for="option in innings" class="select-danger" :key="option" :label="option"
                            :value="option">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                    <div class="col-4">
                      <base-input class="select-danger">
                        <el-select v-model="typeFilter" clearable placeholder="Type">
                          <el-option v-for="option in types" class="select-danger" :key="option" :label="option"
                            :value="option">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>

                    <div class="col-4" v-if="players.length > 0">
                      <base-input class="select-danger">
                        <el-select v-model="playerFilter" filterable clearable placeholder="Batter">
                          <el-option v-for="option in players" class="select-danger" :key="option.ellipsePlayerId"
                            :label="option.fullName" :value="option.ellipsePlayerId">
                          </el-option>
                        </el-select>
                      </base-input>
                    </div>
                  </div>
                  <div class="
                col-12
                d-flex
                justify-content-center
                flex-wrap
                py-2
              ">
                    <div class="mr-4">
                      <p class="card-category">
                        Showing {{ from + 1 }} to {{ to }} of {{ streamClips.length }} entries
                      </p>

                    </div>
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                      :per-page="pagination.chunkSize" :total="streamClips.length"></base-pagination>
                  </div>
                </div>

                <div class="card-body">
                  <div class="row">
                    <div class="col-12 mb-3" v-for="clip in streamClipsChunked" :key="clip.id">

                      <stream-clip :clip="clip"></stream-clip>

                    </div>
                  </div>
                </div>
              </div>

            </tab>
            <tab v-if="archiveClips.length > 0">
              <span slot="title">
                Archive Clips: {{ archiveClips.length }}
              </span>
              <div class="card shadow mt-3">
                <div class="h3 mb-0">{{ match.team1Name }} vs {{ match.team2Name }}</div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-4"><archive-clip v-for="clip in archiveClips" :clip="clip"></archive-clip></div>
                  </div>
                </div>
              </div>
            </tab>

            <tab v-if="recordingsAll.length > 0">
              <span slot="title">
                Recordings: {{ recordingsAll.length }}
              </span>
              <div class="card shadow mt-3">

                <div class="card-body">
                  <div class="row mb-4" v-for="day, key in recordings">
                    <div class="col-1 h4">Day {{ key }}</div>
    
                        <div class="col-2" v-for="recording in day">
                      <a :href="`#/recording/${recording.id}`" class="btn btn-secondary btn-block">Part {{ recording.part }}</a></div>
         
                    </div>
                    
                </div>
              </div>
            </tab>

          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/routes/router";
import tabs from "@/components/Tabs/Tabs";
import tab from "@/components/Tabs/Tab";
import dateTime from "../../../filters/dateTime";
import ReelCard from "./Components/ReelCard";
import ArchiveClip from "./Components/ArchiveClip";
import ArchiveUploader from "./Components/ArchiveUploadWrapper";


import StreamClip from './Components/StreamClip.vue'

export default {
  name: "Match-Page",
  data() {
    return {
      inningsFilter: '',
      playerFilter: '',
      typeFilter: '',
      pagination: {
        chunkSize: 20,
        currentPage: 1,
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    matchId() {
      return this.$route.params.id;
    },
    match() {
      let match = this.$store.getters["matches/byId"](this.$route.params.id);
      if (match) {
        return match
      }
    },
    venue() {
      var id = this.$store.getters["venues/byId"](this.match.ground);
      if (id) {
        return id;
      } else {
        this.$store.dispatch("venues/fetchById", this.match.ground);
      }
    },
    format() {
      return this.$store.getters["matchFormats/formatById"](this.match.cricketFormat);
    },
    reels() {
      let reels = this.$store.getters["files/whatByMatch"]({ what: 'match-reel', id: this.match.id });
      return reels.sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1
        }
        if (a.created_at < b.created_at) {
          return 1
        }
        if (!a.created_at) {
          return -1
        }
        return 0
      })
    },
    streamClips() {
      let clips = this.$store.getters["files/whatByMatch"]({ what: 'match-clip', id: this.match.id });
      // filter
      if (this.inningsFilter) {
        clips = clips.filter(clip => clip.innings === this.inningsFilter)
      }
      if (this.typeFilter) {
        clips = clips.filter(clip => clip.clipType === this.typeFilter)
      }
      if (this.playerFilter) {
        clips = clips.filter(clip => clip.ellipseBatterId === this.playerFilter)
      }
      return clips.sort((a, b) => {
        if (a.created_at > b.created_at) {
          return -1
        }
        if (a.created_at < b.created_at) {
          return 1
        }
        if (!a.created_at) {
          return -1
        }
        return 0
      })
    },
    streamClipsChunked() {
      function getChunk(array, chunkSize, index) {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
          chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks[index];
      }

      return getChunk(this.streamClips, this.pagination.chunkSize, this.pagination.currentPage - 1);

    },
    innings() {
      let clips = this.streamClips
      const uniqueInnings = this.uniquePropertyValues(clips, 'innings')
      return [...uniqueInnings]
    },
    types() {
      let clips = this.streamClips
      const uniqueTypes = this.uniquePropertyValues(clips, 'clipType')
      return [...uniqueTypes]
    },
    players() {
      let clips = this.streamClips
      const uniquePlayers = this.uniquePropertyValues(clips, 'ellipseBatterId')
      let ids = [...uniquePlayers]
      ids.forEach(player => {
        const id = this.$store.getters["players/byProp"]("ellipsePlayerId", player)
        if (!id) {
          this.$store.dispatch("players/fetchAndAdd", {
            where: [["ellipsePlayerId", "==", player]],
          });
        }
      })
      return this.$store.getters["players/byPropMultiple"]("ellipsePlayerId", ids).sort((a, b) => {
        if (a.lastName > b.lastName) {
          return 1
        }
        if (a.lastName < b.lastName) {
          return -1
        }
        if (!a.lastName) {
          return -1
        }
        return 0
      })
    },
    to() {
      let highBound = this.from + this.pagination.chunkSize;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.chunkSize * (this.pagination.currentPage - 1);
    },
    total() {
      return this.streamClips.length > 0
        ? this.streamClips.length
        : this.streamClips.length;
    },
    recordingsAll() {
      return this.$store.getters["files/whatByMatch"]({ what: 'recording', id: this.match.id })
    },
    recordings() {
      let recordings = this.$store.getters["files/whatByMatch"]({ what: 'recording', id: this.match.id })
      const groupedData = recordings.reduce((acc, obj) => {
  const key = obj.day; // Property to group by
  if (!acc[key]) {
    acc[key] = [];
  }
  acc[key].push(obj);
  return acc;
}, {});

// Sort each array
for (const key in groupedData) {
  if (groupedData.hasOwnProperty(key)) {
    groupedData[key].sort((a, b) => a.part - b.part) // Sort by part
  }
}



return groupedData
      // recordings.sort((a, b) => {
      //   if (a.part > b.part) {
      //     return 1
      //   }
      //   if (a.part < b.part) {
      //     return -1
      //   }
      //   if (!a.part) {
      //     return -1
      //   }
      //   return 0
      // })
      // return recordings.sort((a, b) => {
      //   if (a.day > b.day) {
      //     return 1
      //   }
      //   if (a.day < b.day) {
      //     return -1
      //   }
      //   if (!a.day) {
      //     return -1
      //   }
      //   return 0
      // })
    },
    archiveClips() {
      return this.$store.getters["archiveEvents/byMatchId"](this.match.id);
    },
  },
  components: {
    tab, tabs,
    "archive-upload-wrapper": ArchiveUploader,
    "reel-card": ReelCard,
    "archive-clip": ArchiveClip,
    StreamClip,
  },

  beforeMount() {
    this.$store.dispatch("matches/fetchById", this.$route.params.id);
    this.$store.dispatch("files/openDBChannel", {
      where: [["match", "==", this.matchId]],
    });
    this.$store.dispatch("archiveEvents/fetchAndAdd", {
      where: [["match", "==", this.matchId]],
    });

  },
  methods: {
    uniquePropertyValues(array, property) {
      return array.reduce((uniqueValues, obj) => {
        uniqueValues.add(obj[property]);
        return uniqueValues;
      }, new Set());
    }

  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>