<template>
  <div>
    {{venue.name}}
  </div>
</template>

<script>

export default {
  data() {
    return {};
  },
  props: ["venueID"],
  computed: {
    venue() {
      const venue = this.$store.getters["venues/byId"](this.venueID);
      if(venue) {
        return venue;
      } else {
        this.$store.dispatch("venues/fetchById", this.venueID);
        return {
          
        }
      } 
    },
  },
  components: {},
  mounted() {

  },
};
</script>

<style scoped>
/* 
.card{
  position: inherit;
} */
</style>