<template>
  <div>
    <base-header class="pb-9" type="danger">
      <!-- <div class="row align-items-center">
        <div class="col">
          <h1 class="display-3 text-white">Recent Recordings</h1>
          <p class="text-white">Recordings from the last 12 months</p>
        </div>
      </div> -->
    </base-header>
    <div class="container-fluid mt--8">
      <div class="row">
        <div class="col">
          <div class="card shadow">
            <div class="card-header border-0">
              <div class="row align-items-center">
                <div class="col">
                  <h3 class="mb-0">{{ recordings.length }} Recordings Found</h3>
                </div>
                <div class="col text-right">
                  <base-button size="sm" type="primary" @click="refreshData">Refresh Data</base-button>
                  <base-button size="sm" type="success" @click="exportToExcel" class="ml-2" :disabled="exporting">
                    <i class="ni ni-cloud-download-95 mr-1"></i> 
                    <span v-if="!exporting">Export to Excel</span>
                    <span v-else>Exporting...</span>
                  </base-button>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <el-table 
                :data="recordings" 
                style="width: 100%"
                v-loading="loading"
                row-key="id"
                header-row-class-name="thead-light">
    
                <el-table-column label="Date Created" prop="created_at" sortable>
                  <template v-slot="{ row }">
                    {{ row.created_at | dateAndTime }}
                  </template>
                </el-table-column>
                <el-table-column label="Match" prop="match">
                  <template v-slot="{ row }">
                    <match-look-up v-if="row.match" :matchID="row.match"></match-look-up>
                    <span v-else class="text-muted">No match</span>
                  </template>
                </el-table-column>
                <el-table-column label="Date" prop="match">
                  <template v-slot="{ row }">
                    <match-date v-if="row.match" :matchID="row.match"></match-date>
                    <span v-else class="text-muted">No match</span>
                  </template>
                </el-table-column>
                <el-table-column label="Day" prop="day">
                  <template v-slot="{ row }">
                {{ row.day }}
                  </template>
                </el-table-column>
                <el-table-column label="Part" prop="part">
                  <template v-slot="{ row }">
                {{ row.part }}
                  </template>
                </el-table-column>
                <el-table-column label="Path" prop="sourcePath">
                  <template v-slot="{ row }">
                {{ row.sourcePath }}
                  </template>
                </el-table-column>
                <el-table-column label="Size" prop="size" sortable>
                  <template v-slot="{ row }">
                    {{ formatFileSize(row.size) }}
                  </template>
                </el-table-column>
                <el-table-column label="Actions" width="150">
                  <template v-slot="{ row }">
                    <a :href="`/#/recording/${row.id}`" class="btn btn-sm btn-primary">
                      <i class="ni ni-button-play"></i> View
                    </a>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div class="card-footer py-4" v-if="recordings.length === 0 && !loading">
              <div class="text-center text-muted">
                <p>No recordings found from the last 12 months</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MatchLookUp from "./Components/MatchLookUp";
import MatchDate from "./Components/MatchDate";
import * as XLSX from 'xlsx';

export default {
  components: {
    "match-look-up": MatchLookUp,
    "match-date": MatchDate
  },
  data() {
    return {
      loading: true,
      exporting: false
    };
  },
  computed: {
    recordings() {
      const allFiles = this.$store.getters["files/files"];
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 6);
      
      return allFiles.filter(file => {
        // Filter for files where what is 'recording'
        if (file.what !== 'recording') return false;
        
        // Filter for files created in the last 12 months
        if (!file.created_at) return false;
        
        const createdDate = new Date(file.created_at);
        return createdDate >= twelveMonthsAgo;
      });
    }
  },
  beforeMount() {
    this.fetchData();
    // Fetch teams and matches data for export
    this.$store.dispatch("teams/fetchAndAdd");
    // this.$store.dispatch("matches/fetchAndAdd");
  },
  methods: {
    fetchData() {
      this.loading = true;
      const twelveMonthsAgo = new Date();
      twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 6);
      // Fetch all files where what is 'recording'
      this.$store.dispatch("files/openDBChannel", {
        where: [["what", "==", "recording"], ["created_at", ">=", twelveMonthsAgo]
        ],
        // clauses: { limit: 1000 }
      }).then(() => {
        this.loading = false;
      }).catch(error => {
        console.error("Error fetching recordings:", error);
        this.loading = false;
      });
    },
    refreshData() {
      this.fetchData();
    },
    formatFileSize(bytes) {
      if (!bytes) return 'Unknown';
      
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
      if (bytes === 0) return '0 Byte';
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    exportToExcel() {
      this.exporting = true;
      
      // Make sure we have all the match data
      const matchIds = this.recordings
        .filter(recording => recording.match)
        .map(recording => recording.match);
      
      // Create a promise to fetch all match data if not already loaded
      const fetchMatchesPromise = Promise.all(
        matchIds.map(matchId => {
          const match = this.$store.getters["matches/byId"](matchId);
          if (!match) {
            return this.$store.dispatch("matches/fetchById", matchId);
          }
          return Promise.resolve();
        })
      );
      
      // After matches are loaded, fetch teams if needed
      fetchMatchesPromise.then(() => {
        const teamIds = new Set();
        
        // Collect all team IDs from matches
        matchIds.forEach(matchId => {
          const match = this.$store.getters["matches/byId"](matchId);
          if (match) {
            if (match.team1) teamIds.add(match.team1);
            if (match.team2) teamIds.add(match.team2);
          }
        });
        
        // Fetch team data if not already loaded
        return Promise.all(
          Array.from(teamIds).map(teamId => {
            const team = this.$store.getters["teams/byId"](teamId);
            if (!team) {
              return this.$store.dispatch("teams/fetchById", teamId);
            }
            return Promise.resolve();
          })
        );
      })
      .then(() => {
        // Prepare data for export
        const exportData = this.recordings.map(recording => {
          // Get match details if available
          let matchDetails = '';
          let competition = '';
          let competitionName = '';
          let venue = '';
          let date = '';
          // console.log(recording);
          const match = recording.match ? this.$store.getters["matches/byId"](recording.match) : null;

          if (match) {
            const team1 = this.$store.getters["teams/byId"](match.teamId1);
            const team2 = this.$store.getters["teams/byId"](match.teamId2);
            if (team1 && team2) {
              matchDetails = `${team1.teamName} vs ${team2.teamName}`;
            }
            date = match.startDate;
            competition = match.competition || '';
            competitionName = match.competitionName || '';
            venue = match.ground || '';
          }
          
          return {
            'Date Created': recording.created_at ? new Date(recording.created_at).toLocaleString() : '',
            'Match': matchDetails,
            'Competition': competition,
            'Competition Name': competitionName,
            'Date': date,
            'Day': recording.day || '',
            'Part': recording.part || '',
            'Source Path': recording.sourcePath || '',
            'Size': this.formatFileSize(recording.size),
            'ID': recording.id
          };
        });
        
        // Create worksheet
        const worksheet = XLSX.utils.json_to_sheet(exportData);
        
        // Auto-size columns
        const colWidths = exportData.reduce((acc, row) => {
          Object.keys(row).forEach(key => {
            const length = (row[key] && row[key].toString().length) || 0;
            acc[key] = Math.max(acc[key] || 0, length, key.length);
          });
          return acc;
        }, {});
        
        // Set column widths
        worksheet['!cols'] = Object.keys(colWidths).map(key => ({ wch: colWidths[key] + 2 }));
        
        // Create workbook
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Recent Recordings');
        
        // Generate file name with current date
        const date = new Date().toISOString().split('T')[0];
        const fileName = `recent_recordings_${date}.xlsx`;
        
        // Export to file
        XLSX.writeFile(workbook, fileName);
        
        // Show success notification
        this.$notify({
          title: 'Export Complete',
          message: `Successfully exported ${exportData.length} recordings to ${fileName}`,
          type: 'success',
          duration: 5000
        });
        
        this.exporting = false;
      })
      .catch(error => {
        console.error("Error exporting data:", error);
        
        // Show error notification
        this.$notify({
          title: 'Export Failed',
          message: 'There was an error exporting the data. Please try again.',
          type: 'error',
          duration: 5000
        });
        
        this.exporting = false;
      });
    }
  }
};
</script>

<style scoped>
.preview-thumbnail {
  width: 120px;
  height: 67px;
  overflow: hidden;
  background-color: #f8f9fe;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview-thumbnail img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}
</style> 